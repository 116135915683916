import React from "react"
import t from "prop-types"
import { withBaseComponent } from "../../core/BaseComponent"
class Column extends React.Component {
  static propTypes = {
    className: t.string,
    right: t.string,
    center: t.string,
    xs: t.number,
    sm: t.number,
    md: t.number,
    lg: t.number,
    children: t.node.isRequired,
  }

  render() {
    const { className, xs, sm, md, lg } = this.props
    let classNames = className ? `${className} col ` : "col "

    xs ? (classNames += `col-${xs} `) : (classNames += `col-12 `)
    sm ? (classNames += `col-sm-${sm} `) : (classNames += ``)
    md ? (classNames += `col-md-${md} `) : (classNames += ``)
    lg ? (classNames += `col-lg-${lg} `) : (classNames += ``)

    return <div className={classNames}>{this.props.children}</div>
  }
}

export default withBaseComponent(Column)
