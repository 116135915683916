import React, { Component } from "react"
import t from "prop-types"
// import ButtonMenu from './ButtonMenu';
import { Link, graphql } from "gatsby"
import GatsbyImage from "../../gatsby/image"

class Header extends Component {
  static propTypes = {
    data: t.shape({
      hideMobileNavOnScroll: t.bool,
      logo: t.shape({
        title: t.string,
        image: t.string,
        alt: t.string,
      }),
      right: t.arrayOf(
        t.shape({
          label: t.string,
          anchor: t.string,
          hideOnMobile: t.bool,
        })
      ),
    }),
  }

  constructor(props) {
    super(props)
    this.lastScrollPosition = 0
  }

  componentDidMount() {
    /* Feature detection: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners */
    let passiveIfSupported = false
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function() {
            passiveIfSupported = { passive: false }
            return null
          },
        })
      )
    } catch (err) {}

    window.addEventListener(
      "scroll",
      this.onScroll.bind(this),
      passiveIfSupported
    )
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll.bind(this))
  }

  render() {
    const { data } = this.props
    const keys = Object.keys(data)

    return (
      <header
        className="wwc-header header"
        ref={ref => {
          this.header = ref
        }}
      >
        <div className="header__content">
          <Link to={data.logo.url} className="header__logo" title="Home Page">
            <GatsbyImage
              imageName={data.logo.imageName}
              alt={data.logo.alt}
              styles={{ width: "200px" }}
            />
          </Link>

          {keys.map((item, index) => {
            if (item === "left" || item === "center" || item === "right") {
              return this.renderNav(item, data[item], index)
            } else {
              return console.warn(
                `The following key : "${item}" is not expected in Header.js`
              )
            }
          })}

          {/* <ButtonMenu onClick={this.toggleMenu} /> */}
        </div>
      </header>
    )
  }

  renderNav(key, data, index) {
    const className = `nav nav--${key}`
    // const { pagePath, pageLocale } = this.props;

    return (
      <nav className={className} key={`nav__item-${index}`}>
        <ul className="nav-container">
          {data.map((item, index) => {
            if (item.logo) {
              return this.renderLogo(item)
            } else if (item.icon) {
              return this.renderIcon(item)
            } else {
              return this.renderNavItem(item, index)
            }
          })}
        </ul>
      </nav>
    )
  }

  renderIcon() {
    // TODO : Importing icon should be more dynamic.
    return <li>{/* <ButtonMenu onClick={this.openMenu} /> */}</li>
  }

  renderLogo(_data) {
    const { label } = _data
    return (
      <li className={this.getClassName(_data, "header__logo")}>
        <a href="/">
          <h1 itemScope="" itemType="http://schema.org/Organization">
            {label}
          </h1>
        </a>
      </li>
    )
  }

  renderNavItem(_data, index) {
    const { label, url, anchor, external } = _data
    if (external) {
      return (
        <li
          className={this.getClassName(_data, `nav__item`)}
          key={`list-item-${index}`}
        >
          <a href={url} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
        </li>
      )
    } else {
      return (
        <li
          className={this.getClassName(_data, `nav__item`)}
          key={`list-item-${index}`}
        >
          <Link
            to={url}
            title={label}
            onClick={e => this.onNavItemClick(e, anchor)}
            activeClassName="active"
          >
            {label}
          </Link>
        </li>
      )
    }
  }

  toggleMenu = () => {
    document.body.classList.toggle("menu-active")
  }

  closeMenu = () => {
    document.body.classList.remove("menu-active")
  }

  onNavItemClick = (e, anchor) => {
    this.closeMenu()
    var section = document.getElementById(anchor)
    if (section) {
      e.preventDefault()
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  onScroll = () => {
    if (this.header !== null) {
      if (
        window.pageYOffset >
        this.header.offsetTop + this.header.offsetHeight
      ) {
        this.header.classList.add("header__main--scroll")
        // this.newScrollPosition = window.pageYOffset;

        // if (this.newScrollPosition < this.lastScrollPosition) {
        //   this.header.classList.remove('header__main--hidden');
        // } else {
        //   this.header.classList.add('header__main--hidden');
        // }

        // this.lastScrollPosition = this.newScrollPosition;
      } else {
        this.header.classList.remove("header__main--scroll")
      }
    }
  }

  getClassName(_data, _class) {
    const { hideOnDesktop, hideOnMobile, cta } = _data
    let className = _class
    if (hideOnDesktop) {
      className += ` header--hide-desktop`
    } else if (hideOnMobile) {
      className += ` header--hide-mobile`
    } else if (cta) {
      className += ` btn__cta`
    }

    return className
  }
}

export default Header

export const query = graphql`
  fragment HeaderData on SiteJsonSite {
    header {
      logo {
        title
        imageName
        alt
        url
      }
      right {
        cta
        url
        hideOnMobile
        label
      }
    }
  }
`
