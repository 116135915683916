import React from "react"
import t from "prop-types"
import Section from "../../wwc/layout/Section/Section"
import Row from "../../wwc/layout/Row/Row"
import Column from "../../wwc/layout/Column/Column"
import { graphql } from "gatsby"
import Text from "../../wwc/text/Text/Text"
import Title from "../../wwc/text/Title/Title"
import Form from "./Form"

class Newsletter extends React.Component {
  static propTypes = {
    data: t.shape({
      text: t.shape({
        title: t.string,
        description: t.string,
      }),
    }).isRequired,
  }

  render() {
    const { data } = this.props

    return (
      <>
        <Section id="section-newsletter">
          <Row>
            <Column className="newsletter__container">
              <Title h4>{data.title}</Title>
              <Text>{data.description}</Text>
              <Form data={data.formData} className="contact-form" />
            </Column>
          </Row>
        </Section>
      </>
    )
  }
}

export const query = graphql`
  fragment NewsletterData on SiteJsonSite {
    contact {
      title
      description
    }
  }
`

export default Newsletter
