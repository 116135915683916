import React from "react"
import { graphql } from "gatsby"
import t from "prop-types"
import { Column, Row } from "../layout"
import Button from "../ui/Button/Button"
import Input from "./Input/Input"
import "../polyfill/foreach"

class Form extends React.Component {
  static propTypes = {
    data: t.shape({
      id: t.string.isRequired,
      items: t.array.isRequired,
    }).isRequired,
    className: t.string,
  }

  constructor(props) {
    super(props)
    this.fields = []
  }

  render() {
    const { data, className } = this.props
    const { id, items, successMessage, errorMessage } = data

    return (
      <form
        id="contact-form"
        className={className}
        action="https://cf.wonderful.com/forms/process-form"
        ref={ref => (this.form = ref)}
      >
        <input type="hidden" id="form_id" name="form_id" value={id} />
        <input
          type="hidden"
          id="routing_status"
          name="routing_status"
          value={"True"}
        />
        {items.map((cols, index) => {
          return this.renderColumn(cols, index)
        })}
        <div className="form__message form__message--success">
          {successMessage}
        </div>
        <div className="form__message form__message--error">{errorMessage}</div>
      </form>
    )
  }

  renderColumn(cols, index) {
    return (
      <Row className={`row__form--${index}`} key={`row__form--${index}`}>
        {cols.map((field, index) => {
          return this.renderField(cols.length, field, index)
        })}
      </Row>
    )
  }

  renderField(length, field, index) {
    const { type, label, required } = field
    let colLg = Math.floor(12 / length)

    if (type === "button") {
      return (
        <Column lg={colLg} key={`column-index-${index}`}>
          <div className="form-group">
            <div className={`button-container`}>
              <Button
                className={`transition--slide-up`}
                action={() => this.onClick()}
              >
                {label}
              </Button>
              <div className="loading-container">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </Column>
      )
    }

    return (
      <Column lg={colLg} key={`column-index-${index}`}>
        <Input
          ref={field => {
            if (required) this.fields.push(field)
          }}
          data={field}
        />
      </Column>
    )
  }

  getCookie(cname) {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  async submit() {
    var CONTACT_FORM_ENDPOINT_DEV = "https://dev-cf.wonderful.com"
    var CONTACT_FORM_ENDPOINT_PROD = "https://cf.wonderful.com"
    this.routingStatus = document.querySelector(
      '#contact-form input[name="routing_status"]'
    )

    if (
      window.location.href.indexOf("dev.") >= 0 ||
      window.location.href.indexOf("localhost") >= 0
    ) {
      this.routingStatus.value = "false"
      this.contactFormEndpoint = CONTACT_FORM_ENDPOINT_DEV
    } else {
      this.routingStatus.value = "True"
      this.contactFormEndpoint = CONTACT_FORM_ENDPOINT_PROD
    }

    // change routing to false when automation is running so clients don't get notifications
    if (this.getCookie("automation_gauge") === "true") {
      this.routingStatus.value = "false"
    }

    var formData = new FormData()
    Array.from(this.form.querySelectorAll("input,textarea")).forEach(input => {
      if (input.getAttribute("type") === "radio") {
        if (input.checked === true) {
          formData.append(input.getAttribute("name"), input.value)
        }
      } else if (input.getAttribute("type") === "checkbox") {
        if (input.checked === true) {
          formData.append(input.getAttribute("name"), "true")
        }
      } else {
        formData.append(input.getAttribute("name"), input.value)
      }
    })

    try {
      document.querySelector(".loading-container").classList.add("show")
      fetch(`${this.contactFormEndpoint + "/forms/process-form"}`, {
        method: "POST",
        body: formData,
      })
        .then(responseJson => {
          console.log(responseJson)
          this.success()
        })
        .catch(error => {
          console.log(error)
          this.fail(error)
        })
    } catch (error) {
      console.log(error)
      this.fail(error)
    }
    console.log("Not set up yet")
  }

  success() {
    this.form.reset()
    this.form.querySelector(".form__message--success").classList.add("show")
    this.form.querySelector(".loading-container").classList.remove("show")
  }

  fail(error) {
    this.form.querySelector(".form__message--error").classList.add("show")
    this.form.querySelector(".form__message--error").innerHTML = error
    this.form.querySelector(".loading-container").classList.remove("show")
  }

  onClick() {
    let formHasError = false
    this.fields.forEach(function(field) {
      if (field != null) {
        var fieldHasError = field.validate()
        if (fieldHasError) {
          formHasError = true
        }
      }
    })

    if (!formHasError) {
      this.submit()
    }
  }

  onInput(e) {
    if (e.target.value.length > 0) {
      e.target.parentNode.classList.add("has-focus")
    } else {
      e.target.parentNode.classList.remove("has-focus")
    }
  }
}

// export const query = graphql`
//   fragment NewsletterData on SiteJsonSite {
//     newsletter{
//       title
//       description
//       formData {
//         id
//         successMessage
//         errorMessage
//         items {
//           name
//           label
//           validationMessage
//           type
//           required
//         }
//       }
//     }
//   }
// `
export const query = graphql`
  fragment FormData on SiteJsonSite {
    contact {
      formData {
        id
        successMessage
        errorMessage
        items {
          name
          label
          validationMessage
          type
          required
        }
      }
    }
  }
`

export default Form
