import React from "react"
import { graphql } from "gatsby"
import t from "prop-types"
import Row from "../../wwc/layout/Row/Row"
import Column from "../../wwc/layout/Column/Column"
import { Link } from "gatsby"
import GatsbyImage from "../../gatsby/image"

class Footer extends React.Component {
  static propTypes = {
    data: t.shape({
      logo: t.shape({
        title: t.string,
        url: t.string,
        imageName: t.string,
        alt: t.string,
      }).isRequired,
      contact: t.shape({
        text: t.string,
        link: t.shape({
          label: t.string,
          url: t.string,
        }),
      }).isRequired,
      termsAndPolicy: t.shape({
        links: t.arrayOf(
          t.shape({
            label: t.string,
            url: t.string,
            newTab: t.bool,
          })
        ),
      }),
      copyright: t.string,
    }).isRequired,
  }

  showHeader() {
    let header = document.querySelector(".wwc-header")
    if (header.classList.contains("header__main--hidden")) {
      header.classList.remove("header__main--hidden")
    }
  }

  render() {
    const { data } = this.props

    return (
      <footer>
        <section id="section-footer">
          <Row className={`row__footer-outer`}>
            <Column md={6} className={"footer__logo-container"}>
              <Link
                to={data.logo.url}
                className="footer__logo"
                title="Home Page"
              >
                <GatsbyImage
                  imageName={data.logo.imageName}
                  title={data.logo.title}
                  alt={data.logo.alt}
                  styles={{ width: "200px" }}
                />
              </Link>
            </Column>

            <Column md={6} className={"footer__nav"}>
              <p>
                {data.contact.text}{" "}
                <a className="footer__link--mail" href={data.contact.link.url}>
                  {data.contact.link.label}
                </a>
              </p>
              <ul className={`footer__links`}>
                {data.termsAndPolicy.links.map((item, index) => {
                  return (
                    <li key={`footer__links--item-${index}`}>
                      {item.newTab ? (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.label}
                        </a>
                      ) : (
                        <Link to={item.url}>{item.label}</Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            </Column>

            <Column>
              <p className="copyright">
                &copy; {new Date().getFullYear()} {data.copyright}
              </p>
            </Column>
          </Row>
        </section>
      </footer>
    )
  }
}

export default Footer

export const query = graphql`
  fragment FooterData on SiteJsonSite {
    footer {
      logo {
        title
        imageName
        alt
        url
      }
      contact {
        text
        link {
          label
          url
        }
      }
      termsAndPolicy {
        links {
          label
          url
          newTab
        }
      }
      copyright
    }
  }
`
