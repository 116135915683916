import React, { Component } from "react"
import t from "prop-types"
import { graphql } from "gatsby"
import GatsbyImage from "../../../gatsby/image"

class BackToTop extends Component {
  static propTypes = {
    data: t.shape({
      imageName: t.string.isRequired,
      alt: t.string.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.footerEle = document.querySelector("footer")
    window.addEventListener("scroll", this.trackScroll.bind(this))
  }

  trackScroll() {
    if (window.pageYOffset > 400) {
      if (!this.backToTopEle.classList.contains("active")) {
        this.backToTopEle.classList.add("active")
      }
    } else {
      this.backToTopEle.classList.remove("active")
    }

    this.bottomPositioning =
      window.innerHeight +
      window.pageYOffset -
      this.getTopOffset(this.footerEle).top
    if (this.bottomPositioning > 80) {
      this.backToTopEle.style.bottom = `${this.bottomPositioning}px`
    } else {
      this.backToTopEle.style.bottom = `80px`
    }
  }

  scrollToTop(e) {
    e.preventDefault()
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  getTopOffset = el => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  render() {
    const { data } = this.props
    return (
      <button
        className="back-to-top"
        onClick={this.scrollToTop.bind(this)}
        ref={ref => {
          this.backToTopEle = ref
        }}
      >
        <GatsbyImage
          imageName={data.imageName}
          title={data.title}
          alt={data.alt}
          styles={{ width: "65px" }}
        />
        <span className="back-to-top__text">Back to top</span>
      </button>
    )
  }
}

export default BackToTop

export const query = graphql`
  fragment BackToTop on SiteJsonSite {
    backToTop {
      imageName
      alt
    }
  }
`
