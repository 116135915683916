import React from "react"
import t from "prop-types"
import { withBaseComponent } from "../../core/BaseComponent"
class Row extends React.Component {
  static propTypes = {
    flip: t.bool,
    center: t.bool,
    bottom: t.bool,
    className: t.string,
    id: t.string,
    children: t.node.isRequired,
  }

  constructor(props) {
    super(props)
    this.el = React.createRef()
  }

  render() {
    const { flip, id } = this.props
    let { className } = this.props
    const _reverse = flip ? "row-reverse" : ""
    className = className ? className : ""
    return (
      <div ref={this.el} id={id} className={className + " row " + _reverse}>
        {this.props.children}
      </div>
    )
  }

  show() {
    const { show } = this.props
    if (show) {
      show()
    }
  }
}

export default withBaseComponent(Row)
