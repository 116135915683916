/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import CustomLayout from "./wrapPageElement"

export const wrapPageElement = CustomLayout

export const onRouteUpdate = () => {
  // scroll into view if anchor exists on page route updates
  // if (window.location.hash) {
  //   scrollToAnchor()
  // }

  // remove hidden header styles
  if (document.querySelector(".wwc-header")) {
    document
      .querySelector(".wwc-header")
      .classList.remove("header__main--hidden")
  }
  if (document.querySelector("body.menu-active")) {
    document.querySelector("body.menu-active").classList.remove("menu-active")
  }
  scrollToSection();
}

// export const onRenderBody = ({ setBodyAttributes }) => {
// 	setBodyAttributes({
// 		className: 'no-js'
// 	});
// };

export const onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
}

// function scrollToAnchor() {
//   let anchorEle = document.getElementById(window.location.hash.substring(1))
//   if (anchorEle) {
//     var headerOffset = document.querySelector(".wwc-header").offsetHeight
//     let secondaryNavHeight = document.querySelector(
//       "#section-secondary-nav .outer__container"
//     ).offsetHeight

//     if (!document.querySelector(".header__main--scroll")) {
//       headerOffset = 0
//     }
//     var elementPosition = anchorEle.getBoundingClientRect().top
//     var offsetPosition = elementPosition - headerOffset - secondaryNavHeight

//     window.scrollTo({
//       top: offsetPosition,
//     })
//   }
// }


function scrollToSection() {
	let url = window.location.href
	if (url.indexOf('#') != -1) {
		let sectionId = url.substring(
      url.lastIndexOf("#") + 1, 
      url.lastIndexOf("?")
    );
		let section = document.getElementById(sectionId);
		if (section) {
			let primaryNav = document.querySelector(".wwc-header");
			let secondaryNav = document.querySelector("#section-secondary-nav .outer__container");
			let sectionPosition = Math.floor(section.getBoundingClientRect().top);
			let primaryNavHeight = primaryNav ? primaryNav.offsetHeight : 0;
			let secondaryNavHeight = secondaryNav ? secondaryNav.offsetHeight : 0;
			let adjustedTop = sectionPosition - primaryNavHeight - secondaryNavHeight;
			window.scrollTo({
				top: adjustedTop,
				behavior: "smooth"
			});
		}
	}
}

