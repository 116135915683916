module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wonderful Innovations","short_name":"INN","start_url":"/","background_color":"#FFFFFF","theme_color":"#8DC63F","display":"minimal-ui","icon":"./static/images/common/icons-512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.wonderfulinnovation.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-52656321-11"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
