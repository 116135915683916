import React from "react"
import t from "prop-types"
import { withBaseComponent } from "../../core/BaseComponent"
class Section extends React.Component {
  static propTypes = {
    id: t.string,
    className: t.string,
    style: t.shape(),
    children: t.node.isRequired,
    show: t.func,
  }

  constructor(props) {
    super(props)
    this.el = React.createRef()
  }

  render() {
    const { id, className, style } = this.props
    const _className = className ? `${className} section` : "section"
    return (
      <section ref={this.el} id={id} style={style} className={_className}>
        <div className="container">{this.props.children}</div>
      </section>
    )
  }

  show() {
    const { show } = this.props
    if (show) {
      show()
    }
  }
}

export default withBaseComponent(Section)
