import React from "react"
import t from "prop-types"
class Text extends React.Component {
  static propTypes = {
    className: t.string,
    children: t.string.isRequired,
    html: t.string,
  }

  render() {
    const { children, className, html } = this.props

    if (html) {
      // React.Fragment + dangerouslySetInnerHTML don't work together
      return (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      )
    }

    return (
      <p className={className} dangerouslySetInnerHTML={{ __html: children }} />
    )
  }
}

export default Text
