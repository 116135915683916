import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Note: You can change "images" to whatever you'd like.

const GatsbyImage = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.edges.find(n => {
          return n.node.relativePath.includes(props.imageName)
        })
        if (!image) {
          return null
        }

        //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
        return (
          <Img
            alt={props.alt}
            fluid={image.node.childImageSharp.fluid}
            style={props.styles}
            loader={props.loading}
            className={props.className}
          />
        )
      }}
    />
  )
}

export default GatsbyImage
