import React from "react"
import t from "prop-types"
import "../../polyfill/foreach"

class Input extends React.Component {
  static propTypes = {
    data: t.shape({
      type: t.string.isRequired,
      name: t.string.isRequired,
      label: t.string,
      required: t.bool,
      options: t.arrayOf(
        t.shape({
          label: t.string,
          value: t.string,
        })
      ),
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
    this.labelId = `input-${Math.floor(Math.random() * 10000000000)}`
  }

  componentDidMount() {
    if (this.input.length > 0) {
      this.input.classList.add("not-empty")
    }
  }

  renderInputDefault() {
    const { type, label, name, required } = this.props.data
    return (
      <React.Fragment>
        <label htmlFor={this.labelId}>
          {label}:{required && <sup className={`required`}>*</sup>}
        </label>
        <input
          id={this.labelId}
          name={name}
          ref={input => (this.input = input)}
          type={type || "text"}
          required={required}
          placeholder={label}
          onInput={this.onInput}
          onBlur={() => this.onBlur()}
          onFocus={() => this.onFocus()}
        />
      </React.Fragment>
    )
  }

  renderInputRadio() {
    const { type, label, name, required, options } = this.props.data
    return (
      <React.Fragment>
        <label>
          {label}:{required && <sup className={`required`}>*</sup>}
        </label>
        {options.map((option, index) => {
          return (
            <div className="radio-input-container">
              <input
                id={option.value}
                name={name}
                ref={input => (this.input = input)}
                type={type || "text"}
                required={required}
                placeholder={label}
                onInput={this.onInput}
                onBlur={() => this.onBlur()}
                onFocus={() => this.onFocus()}
                value={option.value}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  renderInputCheckbox() {
    const { label, name, required } = this.props.data
    return (
      <div>
        <div className="checkbox">
          <label htmlFor={this.labelId}>
            <input
              id={this.labelId}
              name={name}
              ref={input => (this.input = input)}
              type="checkbox"
              required={required}
              onChange={() => this.onChange()}
              onBlur={() => this.onBlur()}
              onFocus={() => this.onFocus()}
            />
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <polygon
                className="st0"
                points="434.8,49 174.2,309.7 76.8,212.3 0,289.2 174.1,463.3 196.6,440.9 196.6,440.9 511.7,125.8 434.8,49"
              ></polygon>
            </svg>
            <span dangerouslySetInnerHTML={{ __html: label }}></span>
          </label>
        </div>
      </div>
    )
  }

  renderInputTextarea() {
    const { label, name, required } = this.props.data
    return (
      <React.Fragment>
        <label htmlFor={this.labelId}>
          {label}:{required && <sup className={`required`}>*</sup>}
        </label>
        <textarea
          id={this.labelId}
          name={name}
          ref={input => (this.input = input)}
          required={required}
          placeholder={label}
          onInput={this.onInput}
          onBlur={() => this.onBlur()}
          onFocus={() => this.onFocus()}
        />
      </React.Fragment>
    )
  }

  renderInputText() {
    const { type, label, name, required } = this.props.data
    return (
      <React.Fragment>
        <input
          id={this.labelId}
          name={name}
          ref={input => (this.input = input)}
          type={type || "text"}
          required={required}
          placeholder={label}
          onInput={this.onInput}
          onBlur={() => this.onBlur()}
          onFocus={() => this.onFocus()}
        />
        <label htmlFor={this.labelId}>{label}</label>
      </React.Fragment>
    )
  }

  renderInputs() {
    const { type } = this.props.data
    switch (type) {
      case "radio":
        return this.renderInputRadio()
      case "checkbox":
        return this.renderInputCheckbox()
      case "textarea":
        return this.renderInputTextarea()
      default:
        return this.renderInputDefault()
    }
  }

  render() {
    const { error } = this.state
    const { type, errorMessage } = this.props.data

    let _className = "wwc-input-text"

    switch (type) {
      case "radio":
        _className = "wwc-input-radio"
        break
      case "checkbox":
        _className = "wwc-input-checkbox"
        break
      default:
        _className = "wwc-input-text"
        break
    }

    const className = `form-group ${_className}`

    return (
      <div className={className} data-empty="true" data-error={Boolean(error)}>
        {this.renderInputs()}
        <div className="error">{errorMessage || error}</div>
      </div>
    )
  }

  validate() {
    const { type, required } = this.props.data

    if (!required) {
      return false
    } else {
      if (type === "email") {
        return this.validateEmailField()
      } else if (type === "checkbox") {
        return this.validateCheckbox()
      } else if (type === "radio") {
        return this.validateRadio()
      } else {
        return this.validateTextField()
      }
    }
  }

  validateEmailField() {
    // https://emailregex.com/
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
    if (!regex.test(this.input.value)) {
      this.setState({ error: this.props.data.validationMessage })
      return true
    } else {
      this.setState({ error: "" })
      return false
    }
  }

  validateCheckbox() {
    if (!this.input.checked) {
      this.setState({ error: this.props.data.validationMessage })
    } else {
      this.setState({ error: "" })
      return false
    }
    return !this.input.checked
  }

  validateRadio() {
    let errFlag = false
    document
      .querySelectorAll(`[name=${this.input.name}]`)
      .forEach(function(ele) {
        if (ele.checked) {
          errFlag = true
        }
      })

    if (!errFlag) {
      this.setState({ error: this.props.data.validationMessage })
    } else {
      this.setState({ error: "" })
      return false
    }
    return !this.input.checked
  }

  validateTextField() {
    // const { label } = this.props.data;
    if (this.input.value.length === 0) {
      this.setState({ error: this.props.data.validationMessage })
      return true
    } else {
      this.setState({ error: "" })
      return false
    }
  }

  onChange() {
    this.input.value = this.input.checked
  }

  onInput(e) {
    if (e.target.value.length > 0) {
      e.target.parentNode.setAttribute("data-empty", false)
    } else {
      e.target.parentNode.setAttribute("data-empty", true)
    }
  }

  onBlur() {
    this.input.parentNode.classList.remove("has-focus")
  }

  onFocus() {
    const { label } = this.props.data
    this.input.setAttribute("placeholder", label)
    this.input.parentNode.classList.add("has-focus")
    this.setState({ error: "" })
  }
}

export default Input
