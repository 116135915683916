import React from "react"
import PropTypes from "prop-types"

class Button extends React.Component {
  static propTypes = {
    action: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
    href: PropTypes.string,
    animation: PropTypes.string,
  }

  render() {
    const { className, href, action, children, animation } = this.props
    const _className = `wwc-button ${className}`

    switch (animation) {
      case "blob":
        return this.renderBlob()
      default:
      // exit
    }

    if (action) {
      return (
        <button onClick={e => this.handleClick(e)} className={_className}>
          {children}
        </button>
      )
    } else if (href) {
      if (href.indexOf("www") > 0 || href.indexOf("http") > 0) {
        return (
          <a
            target="_blank"
            href={href}
            className={_className}
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )
      }
    }
    return (
      <a href={href} className={_className} rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  renderBlob() {
    const { className, href, children } = this.props
    const _className = `wwc-button ${className}`
    return (
      <a href={href} className={_className}>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="copy">{children}</span>
      </a>
    )
  }

  handleClick = e => {
    e.preventDefault()
    this.props.action()
  }
}

export default Button
