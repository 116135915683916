import React from "react"
import "../polyfill/intersection-observer"
import "../polyfill/foreach"

export function withBaseComponent(Component) {
  return class BaseComponent extends React.Component {
    componentDidMount() {
      if (this.component && this.component.el) {
        this.el = this.component.el.current
        this.component.el = this.el
      }

      if (typeof this.component.show === "function") {
        window.addEventListener("scroll", this.onScroll)
        this.onScroll()
      }
    }

    onScroll = () => {
      // TODO : this.component shouldn't be undefined
      if (this.component && this.isAnyPartOfElementInViewport(this.el)) {
        this.component.show()
        this.el.classList.add("active")
        window.removeEventListener("scroll", this.onScroll)
      }
    }

    isAnyPartOfElementInViewport = el => {
      const rect = el.getBoundingClientRect()
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth

      const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0
      const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0

      return vertInView && horInView
    }

    observe() {
      const config = {
        threshold: [0.65],
      }

      const imageObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // this.component.show();
          } else {
            if (this.component && typeof this.component.hide === "function") {
              this.component.hide()
            }
          }
        })
      }, config)
      imageObserver.observe(this.el)
    }

    render() {
      return (
        <Component ref={ref => (this.component = ref)} {...this.props}>
          {this.props.children}
        </Component>
      )
    }
  }
}
