import React, { Component } from "react"
import { graphql } from "gatsby"
import Header from "./app/Header/Header"
import Footer from "./app/Footer/Footer"
import BackToTop from "./wwc/media/BackToTop/BackToTop"
import { Helmet } from "react-helmet"
import Transition from "./gatsby/transition"
import Newsltter from "./wwc/form/Newsletter"
import "../styles/styles.scss";

const LocaleContext = React.createContext()

class Layout extends Component {
  render() {
    const { children, data, pageContext, path, location } = this.props

    if (typeof data === "undefined") {
      return <>{/* GatsbyJS build fix */}</>
    }

    const {
      header,
      footer,
      social,
      title,
      description,
      locale,
      url,
      contact,
      ogimage,
      ogimageSecured,
      ogimageWidth,
      ogimageHeight,
      backToTop,
    } = data.siteJson.site

    return (
      <>
        <Helmet>
          <html lang={locale} className={`${pageContext.pageName}`} dir="ltr" />
          <title>{title}</title>

          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:locale" content={locale} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ogimage} />
          <meta property="og:image:secure_url" content={ogimageSecured} />
          <meta property="og:image:width" content={ogimageWidth} />
          <meta property="og:image:height" content={ogimageHeight} />

          {/* Adobe Typekit - Wonderful Innovations */}
          <link rel="stylesheet" href="https://use.typekit.net/upb4tru.css" />
        </Helmet>

        <Header data={header} pagePath={path} pageLocale={pageContext.locale} />

        <Transition location={location}>
          <main role="main">{children}</main>
        </Transition>

        <Newsltter data={contact} />
        <Footer data={footer} socialData={social} />
        <BackToTop data={backToTop} />
      </>
    )
  }
}

export { Layout, LocaleContext }

export const query = graphql`
  fragment SiteData on SiteJson {
    site {
      url
      title
      description
      locale
      ogimage
      ogimageSecured
      ogimageWidth
      ogimageHeight
      ...HeaderData
      ...FooterData
      ...FormData
      ...NewsletterData
      ...BackToTop
    }
  }
`
