import React from "react"
import t from "prop-types"

class Title extends React.Component {
  static propTypes = {
    h1: t.bool,
    h2: t.bool,
    h3: t.bool,
    h4: t.bool,
    h5: t.bool,
    h6: t.bool,
    className: t.string,
    children: t.string.isRequired,
  }

  render() {
    const { h1, h2, h3, h4, h5, h6, className, children } = this.props

    if (h1) {
      return (
        <h1
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h1>
      )
    } else if (h2) {
      return (
        <h2
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h2>
      )
    } else if (h3) {
      return (
        <h3
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h3>
      )
    } else if (h4) {
      return (
        <h4
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h4>
      )
    } else if (h5) {
      return (
        <h5
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h5>
      )
    } else if (h6) {
      return (
        <h6
          className={className}
          dangerouslySetInnerHTML={{ __html: children }}
        ></h6>
      )
    } else {
      return <div dangerouslySetInnerHTML={{ __html: children }}></div>
    }
  }
}

export default Title
